import React, { Component } from 'react';
import Layout from '../components/layout';
import { Sidebar } from '../components/sidebar';
import { graphql } from 'gatsby';
import { Tile } from '../components/tile';
import tileStyle from '../components/tile.module.scss';
import { MapboxMap } from '../components/mapbox-map';
import { Footer } from '../components/footer';
import { isEqual } from 'lodash';
import { TitleText } from '../components/title-text';
import WarningPost from '../components/warning-post';
import { containsAll, get } from '../api/localstorage-provider';

export default class MapPage extends Component {
  constructor(props) {
    super(props);
    const { districts, pageData } = props.data;
    this.tiles = pageData.edges[0].node.tiles;
    this.fallBackHover = [];
    this.pageDescription = pageData.edges[0].node.description;
    this.pageDescriptionOverride = pageData.edges[0].node.overrideDescription;
    this.pageTitle = pageData.edges[0].node.title;
    this.pageSubTitle = pageData.edges[0].node.subTitle;
    this.colorCode = pageData.edges[0].node.colorCode;
    this.textHighlightColor = pageData.edges[0].node.textHighlightColor;

    // TODO: maybe move this into gatsby-node
    districts.edges
      .filter(({ node }) => {
        const parts = (node.page || '').split('/');
        return parts.length === 2 && parts[0] === 'map';
      })
      .forEach(({ node }) => {
        const correspondingTile = this.tiles.find(tile => tile.link === node.page);
        if (correspondingTile) {
          correspondingTile.mapFeatures = node.mapFeatures;
          if (correspondingTile.mapFeatures) {
            this.fallBackHover.push(...node.mapFeatures);
          }
        }
      });
    this.update = true;

    const { map } = pageData.edges[0].node;
    this.state = {
      latlng: {
        lat: map.lat,
        lng: map.lng
      },
      zoom: map.zoom,
      mapStyle: map.styles[0].link,
      hoveredElement: this.fallBackHover,
      showModal: false,
      tiles: this.tiles
    };
  }

  componentDidMount() {
    const posted = get('posted');
    this.setState({ posted: posted });
  }

  componentDidUpdate() {
    this.update = true;
  }

  handleClick = (_, e) => {
    this.setState({
      latlng: [e.lngLat.lng, e.lngLat.lat],
      zoom: 14
    });
  };

  showModal = (open = true) => {
    this.setState({ showModal: open });
  };

  getTitleText = () => {
    if (
      !Boolean(this.pageDescriptionOverride) ||
      (Boolean(this.pageDescriptionOverride.needs) &&
        Array.isArray(this.pageDescriptionOverride.needs) &&
        !containsAll('posted', this.pageDescriptionOverride.needs))
    ) {
      return (
        <TitleText
          title={this.pageTitle}
          subTitle={this.pageSubTitle}
          description={this.pageDescription}
          colorCode={this.colorCode}
          textHighlightColor={this.textHighlightColor}
        />
      );
    } else {
      return (
        <TitleText
          title={this.pageTitle}
          subTitle={this.pageSubTitle}
          description={this.pageDescriptionOverride}
          colorCode={this.colorCode}
          textHighlightColor={this.textHighlightColor}
        />
      );
    }

    // (Boolean(e.needs) && Array.isArray(e.needs) && !containsAll('posted', e.needs)
  };

  renderTile = ({ mapFeatures, description, ...e }, current) => {
    let tileClasses = tileStyle.tile;

    return (
      <Tile
        key={e.title}
        showModal={this.showModal}
        className={
          tileClasses +
          ' ' +
          tileStyle[current] +
          (Boolean(e.needs) && Array.isArray(e.needs) && !containsAll(this.state.posted, e.needs)
            ? ' ' + tileStyle['disabled']
            : '')
        }
        description={
          <span>
            {description} <b>mehr...</b>
          </span>
        }
        {...e}
        onMouseOver={() => {
          this.update = false;
          const activeTile = this.tiles.find(el => el.link === e.link);
          this.setState({
            hoveredElement: activeTile.mapFeatures
              ? this.fallBackHover.filter(item => !isEqual(item, activeTile.mapFeatures[0]))
              : this.fallBackHover
          });
        }}
        onMouseOut={() => {
          this.update = false;
          this.setState({ hoveredElement: this.fallBackHover });
        }}
      />
    );
  };

  render() {
    // const activeTile = this.tiles.find(e => e.link === this.state.hoveredElement);

    return (
      <Layout>
        <MapboxMap
          updatePosition={this.update}
          center={[this.state.latlng.lat, this.state.latlng.lng]}
          zoom={this.state.zoom}
          mapFeatures={this.state.hoveredElement}
          mapStyle={this.state.mapStyle}
          minZoom={this.props.data.pageData.edges[0].node.map.minZoom}
          maxZoom={this.props.data.pageData.edges[0].node.map.maxZoom}
        />
        <Sidebar
          render={current => (
            <>
              {this.pageTitle.length > 0 && this.pageSubTitle.length > 0 && this.getTitleText()}

              <div className={tileStyle.tileWrapper}>
                {this.state.tiles.map(tile => this.renderTile(tile, current))}
              </div>
              {this.state.showModal && (
                <WarningPost onClose={() => this.setState({ showModal: false })} />
              )}
            </>
          )}
        />
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  {
    pageData: allDataJson(filter: { page: { eq: "map" } }) {
      edges {
        node {
          title
          subTitle
          colorCode
          textHighlightColor
          description {
            title
            text
          }
          overrideDescription {
            needs
            title
            text
          }
          map {
            lat
            lng
            zoom
            minZoom
            maxZoom
            styles {
              link
              icon
            }
          }
          tiles {
            image {
              file
            }
            title
            needs
            lastUpdated
            colorCode
            textHighlightColor
            letter
            description
            link
            skipContent
          }
        }
      }
    }
    districts: allDataJson {
      edges {
        node {
          page
          mapFeatures {
            fillProperties {
              fill_color
              fill_opacity
            }
            shape
          }
        }
      }
    }
  }
`;
