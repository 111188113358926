import React from 'react';
import { ModuleText } from '../components/module-text';
import { ModuleTextImage } from '../components/module-text-image';
import { ModuleDivider } from '../components/module-divider';
import { ModuleImageRow } from '../components/module-image-row';
import style from './module-col-2.module.scss';
import { ModuleTable } from './module-table';
import { isFunction } from '../utils';

function turnIntoComponent({ type, ...rest }, pseudoId = '') {
  switch (type) {
    case 'module-text':
      return <ModuleText key={type + '-' + pseudoId} {...rest} />;
    case 'module-text-image':
      return <ModuleTextImage key={type + '-' + pseudoId} {...rest} />;
    case 'module-divider':
      return <ModuleDivider key={type + '-' + pseudoId} />;
    case 'module-image-row':
      return <ModuleImageRow key={type + '-' + pseudoId} {...rest} />;
    case 'module-table':
      return <ModuleTable key={type + '-' + pseudoId} {...rest} />;
    default:
      console.warn(`Unhandled case ${type}`);
  }
  return null;
}

export const ModuleCol2 = props => {
  let moduleClass = style.moduleCol2;

  const { left, right, colorCode, textHighlightColor, sidebarView, teaserClickHandler } = props;

  if (sidebarView) {
    moduleClass += ' ' + style[sidebarView];
  }

  let additionalProps = {};

  const isTeaser = isFunction(teaserClickHandler);
  if (isTeaser) {
    additionalProps.onClick = teaserClickHandler;
  }

  return (
    <div className={moduleClass}>
      <div className={style.left}>
        {left.map((buildProps, i) =>
          turnIntoComponent(
            {
              ...buildProps,
              colorCode: colorCode,
              textHighlightColor: textHighlightColor,
              ...additionalProps
            },
            `left-${i}`
          )
        )}
      </div>
      <div className={style.right}>
        {right.map((buildProps, i) =>
          turnIntoComponent(
            {
              ...buildProps,
              colorCode: colorCode,
              textHighlightColor: textHighlightColor,
              ...additionalProps
            },
            `right-${i}`
          )
        )}
      </div>
    </div>
  );
};
