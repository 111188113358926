import React from 'react';
import style from './closeButton.module.scss';

export const CloseButton = React.forwardRef(({ className, ...props }, ref) => {
  let baseStyle = style.closeButton;

  if (className) baseStyle += ' ' + className;
  return (
    <button {...props} ref={ref} className={baseStyle}>
      ✕
    </button>
  );
});
