import React from 'react';
import style from './module-table.module.scss';
import { isFunction } from '../utils';

const noop = () => {};

export const ModuleTable = ({ colorCode, rows, onClick }) => {
  const hasClickHandler = isFunction(onClick);

  let className = style.table;
  if (hasClickHandler) {
    className += ' has-fadeout';
  }

  return (
    <>
      {colorCode && <div style={{ borderTop: `35px solid ${colorCode}` }} />}
      <table
        className={className}
        onClick={hasClickHandler ? onClick : noop}
        style={{ cursor: hasClickHandler ? 'pointer' : 'normal' }}
      >
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.map(({ text, small }, i) => (
                <td key={i}>
                  {text}
                  {small && <span className={style.small}>{small}</span>}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
