import React, { Component } from 'react';
import postCreateStyle from '../../components/post-create.module.scss';
import FileUpload from '../../components/file-upload';
import { isFunction } from '../../utils';
import { Info } from './info';

export class Image extends Component {
  render() {
    const onChange = isFunction(this.props.onChange) ? this.props.onChange : () => {};
    return (
      <>
        <h2>
          {!this.props.required && <span className={postCreateStyle.optionalTag}>optional</span>}
          {this.props.title}
        </h2>
        <div className={postCreateStyle.sectionLeft}>
          <FileUpload mode="post" onChange={onChange} />
        </div>
        <div className={postCreateStyle.sectionRight}>
          <Info text={this.props.info} />
        </div>
      </>
    );
  }
}
