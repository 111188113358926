import React, { Component } from 'react';
import style from './register.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import { navigate } from '@reach/router';
import { ContentPage } from '../components/contentpage';

const API_ENDPOINT = process.env.GATSBY_API_URL;
class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      email: '',
      password: '',
      passwordRepeat: '',
      department: '',
      avatar: '',
      error: null,
      isSending: false
    };
  }
  onUserNameChange = e => {
    this.setState({ username: e.target.value });
  };
  onEmailChange = e => {
    this.setState({ email: e.target.value });
  };
  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };
  onPasswordRepeatChange = e => {
    this.setState({ passwordRepeat: e.target.value });
  };
  onDepartmentChange = e => {
    this.setState({ department: e.target.value });
  };
  onAvatarChange = e => {
    this.setState({ avatar: e.target.value });
  };

  onSubmit = e => {
    const { username, email, password, passwordRepeat, department, avatar } = this.state;
    e.preventDefault();
    this.setState({ error: null, isSending: true });

    if (password === passwordRepeat) {
      fetch(`${API_ENDPOINT}/api/user`, {
        headers: {
          'content-type': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          username: username,
          email: email,
          password: password,
          department: department,
          avatar: avatar
        })
      })
        .then(response => {
          switch (response.status) {
            case 201:
              navigate('/');
              break;
            case 400:
              this.setState({
                error: 'Bitte alle benötigten Felder ausfüllen.',
                isSending: false
              });
              break;
            case 401:
              this.setState({
                error: 'Ihr Zugangscode ist abgelaufen oder nicht gültig.',
                isSending: false
              });
              break;
            case 409:
              this.setState({
                error: 'Benutzername wird schon verwendet.',
                isSending: false
              });
              break;
            default:
              this.setState({
                error: 'Es ist etwas schief gelaufen.',
                isSending: false
              });
          }
        })
        .catch(() => {
          this.setState({
            error:
              'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.',
            isSending: false
          });
        });
    } else {
      this.setState({
        error: 'Passwort stimmt nicht mit Wiederholung überein.',
        isSending: false
      });
    }
  };

  render() {
    const {
      username,
      email,
      password,
      passwordRepeat,
      department,
      avatar,
      error,
      isSending
    } = this.state;
    return (
      <ContentPage>
        <div className={style.register}>
          <h2>Benutzerkonto anlegen</h2>
          <form onSubmit={this.onSubmit}>
            <div className={style.registerGroup}>
              <label>
                <span>Benutzername*</span>
                <input
                  required
                  placeholder="Ingrid Maier"
                  type="text"
                  name="username"
                  value={username}
                  onChange={this.onUserNameChange}
                />
              </label>
              <br />

              <label>
                <span>Email*</span>
                <input
                  required
                  placeholder="ingrid.maier@example.com"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.onEmailChange}
                />
              </label>
              <br />

              <label>
                <span>Password*</span>
                <input
                  required
                  placeholder="********"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onPasswordChange}
                />
              </label>
              <br />

              <label>
                <span>Password wiederholen*</span>
                <input
                  required
                  placeholder="********"
                  type="password"
                  name="passwordRepeat"
                  value={passwordRepeat}
                  onChange={this.onPasswordRepeatChange}
                />
              </label>
              <br />

              <label>
                <span>Beruf / Abteilung</span>
                <input
                  name="department"
                  placeholder="Investor"
                  type="text"
                  value={department}
                  onChange={this.onDepartmentChange}
                />
              </label>
              <br />

              <label>
                <span>Avatar</span>
                <input type="file" name="avatar" value={avatar} onChange={this.onAvatarChange} />
              </label>
              <br />

              {error && <div className="formHint">{error}</div>}
            </div>
            <Button type="submit" modifier={isSending && 'loading'} disabled={isSending}>
              {isSending && <Spinner />}
              Absenden
            </Button>
          </form>
        </div>
      </ContentPage>
    );
  }
}

export default Register;
