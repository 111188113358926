import React, { useEffect } from 'react';
import Layout from '../components/layout';
import { Container } from '../components/container';
import { Footer } from '../components/footer';

const WorkshopPage = ({ children }) => {
  useEffect(() => {
    (function(t, e, s, n) {
      // let o;
      // let a;
      let c;

      const { location } = t;

      t.SMCX = t.SMCX || [];

      // o = e.getElementsByTagName(s);
      // a = o[o.length - 1];
      c = e.createElement(s);
      c.type = 'text/javascript';
      c.async = !0;
      c.id = n;
      c.src = [
        'https:' === location.protocol ? 'https://' : 'http://',
        'widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2Rcr3ZvNB9FkGNchhP4qRJCr3r3iljPJkWSKKbQFpTj.js'
      ].join('');

      let targetContainer = e.getElementsByTagName('main')[0].getElementsByTagName('div')[0]; //e.getElementById('gatsby-focus-wrapper');
      targetContainer.append(c);

      let l = e.createElement('a');
      l.style.font = '12px Helvetica, sans-serif';
      l.style.color = '#999';
      l.href = 'https://de.surveymonkey.com';
      l.appendChild(e.createTextNode('Erstellen Sie Ihre eigene Umfrage zu Nutzerfeedback.'));
      targetContainer.append(l);
    })(window, document, 'script', 'smcx-sdk');
  }, []);

  return (
    <Layout>
      <Container modifier="contentWidth">{children}</Container>
      <Footer fixed={false} />
    </Layout>
  );
};

export default WorkshopPage;
