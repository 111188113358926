import React, { useEffect } from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';
import { get, add } from '../../api/localstorage-provider';

/**
 *
 * @param {object} props
 */
const Email = props => {
  /**
   *
   * @param {string} email
   * @returns {boolean}
   */
  const isEmailValid = email => {
    return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,14}$/.test(email);
  };

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const onChange = event => {
    const email = event.target.value;
    add('email', email);
    if (isFunction(props.onChange) && isEmailValid) props.onChange(email);
  };

  useEffect(() => {
    onChange({ target: { value: get('email') || '' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title, required, email, info, placeholder } = props;

  return (
    <>
      <h2>
        {!required && <span className={postCreateStyle.optionalTag}>optional</span>}
        {title}
      </h2>
      <div className={postCreateStyle.sectionLeft} style={{ paddingRight: '25%' }}>
        <input
          name="email"
          type="email"
          placeholder={placeholder}
          value={email}
          onChange={onChange}
          required
        />
        <span>{!isEmailValid ? 'Geben Sie bitte eine gültige E-Mail an' : ''}</span>
      </div>
      <div className={postCreateStyle.sectionRight}>
        <Info text={info} />
      </div>
    </>
  );
};

export { Email };
