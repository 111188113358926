import React, { Component } from 'react';
import style from './profile.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import FileUpload from '../components/file-upload';
import { navigate } from '@reach/router';
import { patchUser } from '../api/user';
import { UserContext } from '../context/UserContext';
import { ContentPage } from '../components/contentpage';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;

    this.state = {
      username: user.username || '',
      email: user.email || '',
      avatar: user.avatar || '',
      department: user.department || '',

      error: null,
      sendError: null,
      isSending: false,
      password: '',
      passwordRepeat: '',
      oldPassword: ''
    };
  }

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onPasswordRepeatChange = e => {
    this.setState({
      sendError:
        this.state.password !== e.target.value
          ? 'Passwort stimmt nicht mit Wiederholung überein.'
          : '',
      passwordRepeat: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const {
      username,
      email,
      department,
      avatar,
      password,
      passwordRepeat,
      oldPassword
    } = this.state;

    this.setState({ sendError: null, isSending: true });

    if (password !== passwordRepeat) {
      return this.setState({
        sendError: 'Passwort stimmt nicht mit Wiederholung überein.',
        isSending: false
      });
    }

    patchUser({
      username,
      password,
      oldPassword,
      department,
      email,
      avatar
    })
      .then(([user, err]) => {
        if (!err) {
          this.setState({ isSending: false, sendError: null });
          this.context.login({ ...user });
          return navigate('/profile');
        }

        this.setState({ isSending: false, sendError: err.message });
      })
      .catch(e => {
        console.error(e);
        // something completely unexpected happened, since `patchUser()` is only supposed to resolve
        this.setState({ sendError: 'Es ist etwas schief gelaufen.', isSending: false });
      });
  };

  render() {
    let {
      username,
      email,
      department,
      avatar,
      error,
      sendError,
      isSending,
      password,
      passwordRepeat,
      oldPassword
    } = this.state;

    return (
      <ContentPage requireAuth>
        <div className={style.user}>
          <h2>Benutzerkonto bearbeiten</h2>
          <form onSubmit={this.onSubmit}>
            <div className={style.userGroup}>
              <label>
                <span>Benutzername*</span>
                <input
                  required
                  placeholder="Ingrid Maier"
                  type="text"
                  name="username"
                  value={username}
                  onChange={this.onChange}
                />
              </label>
              <label>
                <span>Email*</span>
                <input
                  required
                  placeholder="ingrid.maier@example.com"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                />
              </label>
              <FileUpload
                fieldName="avatar"
                initialFile={avatar}
                onChange={this.onChange}
                label="Avatar"
                mode="profile"
              />
              <br />
              <label>
                <span>Beruf / Abteilung</span>
                <input
                  name="department"
                  placeholder="Investor"
                  type="text"
                  value={department}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <label>
                <span>Password</span>
                <input
                  placeholder="********"
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <label>
                <span>Password wiederholen</span>
                <input
                  placeholder="********"
                  type="password"
                  name="passwordRepeat"
                  autoComplete="new-password"
                  value={passwordRepeat}
                  onChange={this.onPasswordRepeatChange}
                />
              </label>
              <br />
              <label>
                <span>Altes Password</span>
                <input
                  placeholder="********"
                  type="password"
                  name="oldPassword"
                  autoComplete="current-password"
                  value={oldPassword}
                  onChange={this.onChange}
                />
              </label>
              <br />
              {sendError && <div className="formHint">{sendError}</div>}
            </div>

            <Button type="submit" modifier={isSending && 'loading'} disabled={isSending}>
              {isSending && <Spinner />}
              Absenden
            </Button>
          </form>
        </div>
        {error && <div className={style.error}>{error}</div>}
      </ContentPage>
    );
  }
}

ProfilePage.contextType = UserContext;

const userDataToProps = props => {
  return (
    <UserContext.Consumer>
      {data => {
        if (data.loading) {
          return <Spinner modifier={'dark'} />;
        }
        return <ProfilePage {...props} user={data} />;
      }}
    </UserContext.Consumer>
  );
};

export default userDataToProps;
