import React, { useEffect } from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';
// import style from './radioinputs.module.scss';
import styledRadio from '../../components/styled-radio.module.scss';
import { add, get } from '../../api/localstorage-provider';

/**
 *
 * @param {object} props
 */
const Gender = props => {
  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const onChange = event => {
    const gender = event.target.value;
    add('gender', gender);

    if (isFunction(props.onChange)) props.onChange(gender);
  };

  useEffect(() => {
    onChange({ target: { value: get('gender') } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title, gender, info } = props;

  return (
    <>
      {title.length > 0 && <h2>{title}</h2>}
      <div className={postCreateStyle.sectionLeft}>
        <div className={styledRadio.inline}>
          <input
            id="gender1"
            name="gender"
            type="radio"
            value="frau"
            checked={gender === 'frau'}
            onChange={onChange}
          />
          <label htmlFor="gender1">Frau</label>
        </div>

        <div className={styledRadio.inline}>
          <input
            id="gender2"
            name="gender"
            type="radio"
            value="herr"
            checked={gender === 'herr'}
            onChange={onChange}
          />
          <label htmlFor="gender2">Herr</label>
        </div>
      </div>
      <div className={postCreateStyle.sectionRight}>
        <Info text={info} />
      </div>
    </>
  );
};

export { Gender };
