import React from 'react';

const CreateIcon = () => (
  <span
    style={{
      fontSize: 26,
      position: 'relative',
      left: '0',
      top: 3,
      marginRight: 14,
      lineHeight: '0.75'
    }}
  >
    +
  </span>
);

export { CreateIcon };
