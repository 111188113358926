import React, { useEffect } from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';
import { get, add } from '../../api/localstorage-provider';

/**
 *
 * @param {object} props
 */
const Checkbox = props => {
  const {
    checked,
    name,
    title,
    placeholder,
    info,
    required,
    label,
    onChange: onChangeProp
  } = props;

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const onChange = event => {
    const checked = event.target.checked;
    if (isFunction(onChangeProp)) onChangeProp(checked);
  };

  return (
    <>
      {title.length > 0 && <h2>{title}</h2>}

      <div className={postCreateStyle.sectionFull}>
        <div style={{ position: 'relative' }}>
          <label className="checkbox-label">
            <input
              name={name}
              type="checkbox"
              checked={checked}
              placeholder={placeholder}
              onChange={onChange}
              style={{ width: 0, height: 0, margin: 0, padding: 0, opacity: 0 }}
            />
            <span>
              <span className={`checkbox${checked ? ' checked' : ''}`}></span>
              {!required && (
                <span
                  className={postCreateStyle.optionalTag}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginRight: 0
                  }}
                >
                  optional
                </span>
              )}
              {label && ` ${label}`}
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export { Checkbox };
