import React from 'react';
import { ModuleTextImage } from '../components/module-text-image';
import style from './module-text-image.module.scss';

export const ModuleProcess = props => {
  return (
    <div className={style.module}>
      <h2>{props.title}</h2>

      {props.text}
      <div className={style.steps}>
        {props.sections.map(({ ...props }, index) => {
          return (
            <div className={style.step} key={props.image.file}>
              <ModuleTextImage {...props} noLightbox={true} />
              <div className={style.arrow}>
                <div className={style.arrowInner} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
