import React from 'react';
import SEO from '../components/seo';
import { ContentPage } from '../components/contentpage';
import { graphql } from 'gatsby';
import { ModuleText } from '../components/module-text';
import Accordeon, { AccordeonTile } from '../components/accordeon';

const Imprint = ({ data }) => (
  <ContentPage>
    <SEO title="Impressum" />
    <h2>Impressum</h2>
    <Accordeon>
      {data.pages.edges[0].node.sections.map(({ text, title, modules }) => (
        <AccordeonTile title={title} open={title.toLowerCase() === 'impressum'}>
          {text && <p>{text}</p>}
          {modules &&
            modules.map(module => {
              return <ModuleText {...module} />;
            })}
        </AccordeonTile>
      ))}
      <AccordeonTile title="Bildquellen">
        <ul>
          <li>
            <a
              href="https://www.flaticon.com/free-icon/repairing-service_75668"
              target="_blank"
              rel="noopener noreferrer"
            >
              Repairing Service free icon
            </a>{' '}
            made by{' '}
            <a
              href="https://www.freepik.com/"
              title="Freepik"
              target="_blank"
              rel="noopener noreferrer"
            >
              Freepik
            </a>{' '}
            from{' '}
            <a
              href="https://www.flaticon.com/"
              title="Flaticon"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.flaticon.com
            </a>{' '}
            is licensed by{' '}
            <a
              href="http://creativecommons.org/licenses/by/3.0/"
              title="Creative Commons BY 3.0"
              target="_blank"
              rel="noopener noreferrer"
            >
              CC 3.0 BY
            </a>
          </li>
          <li>
            <div>
              <a
                href="https://www.flaticon.com/free-icon/information_685815"
                target="_blank"
                rel="noopener noreferrer"
              >
                Information free icon
              </a>{' '}
              made by{' '}
              <a
                href="https://www.flaticon.com/authors/good-ware"
                title="Good Ware"
                target="_blank"
                rel="noopener noreferrer"
              >
                Good Ware
              </a>{' '}
              from{' '}
              <a
                href="https://www.flaticon.com/"
                title="Flaticon"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.flaticon.com
              </a>{' '}
              is licensed by{' '}
              <a
                href="http://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="https://www.flaticon.com/free-icon/play-button_149125"
                target="_blank"
                rel="noopener noreferrer"
              >
                Play button free icon
              </a>{' '}
              made by{' '}
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Smashicons
              </a>{' '}
              from{' '}
              <a
                href="https://www.flaticon.com/"
                title="Flaticon"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.flaticon.com
              </a>{' '}
              is licensed by{' '}
              <a
                href="http://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="https://www.flaticon.com/free-icon/warning-triangle_10190"
                target="_blank"
                rel="noopener noreferrer"
              >
                Warning Triangle icon
              </a>{' '}
              made by{' '}
              <a
                href="https://www.flaticon.com/authors/amit-jakhu"
                title="Amit Jakhu"
                target="_blank"
                rel="noopener noreferrer"
              >
                Amit Jakhu
              </a>{' '}
              from{' '}
              <a
                href="https://www.flaticon.com/"
                title="Flaticon"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.flaticon.com
              </a>{' '}
              is licensed by{' '}
              <a
                href="http://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="https://www.flaticon.com/free-icon/comment-white-oval-bubble_25663"
                target="_blank"
                rel="noopener noreferrer"
              >
                Comment White Oval Bubble
              </a>{' '}
              made by{' '}
              <a
                href="https://www.flaticon.com/authors/dave-gandy"
                title="Dave Gandy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dave Gandy
              </a>{' '}
              from{' '}
              <a
                href="https://www.flaticon.com/"
                title="Flaticon"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.flaticon.com
              </a>{' '}
              is licensed by{' '}
              <a
                href="http://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </a>
            </div>
          </li>
        </ul>
      </AccordeonTile>
    </Accordeon>
  </ContentPage>
);

export const query = graphql`
  {
    pages: allDataJson(filter: { page: { eq: "imprint" } }) {
      edges {
        node {
          sections {
            title
            modules {
              type
              title
              sections {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default Imprint;
