import React, { Component } from 'react';
import { Info } from './info';
import { isFunction } from '../../utils';

import postCreateStyle from '../../components/post-create.module.scss';
import style from './tags.module.scss';

export class Tags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: []
    };
  }

  onClick = e => {
    const { selected } = this.state;
    const name = e.target.textContent;

    if (selected.indexOf(name) > -1) {
      selected.splice(selected.indexOf(name), 1);
    } else {
      selected.push(name);
    }

    this.setState({ selected });
    if (isFunction(this.props.onChange)) this.props.onChange(selected);
  };

  render() {
    const { items = [] } = this.props;
    const { selected } = this.state;

    return (
      <>
        <h2>
          {!this.props.required && <span className={postCreateStyle.optionalTag}>optional</span>}
          {this.props.title}
        </h2>
        <div className={postCreateStyle.sectionLeft}>
          {items.map(({ name }) => {
            let classNames = style.tag;
            if (selected.indexOf(name) > -1) classNames += ' ' + style['selected'];

            return (
              <span
                key={name.substring(0, 10)}
                onClick={this.onClick}
                className={classNames}
                tabIndex="0"
              >
                {name}
              </span>
            );
          })}
        </div>
        <div className={postCreateStyle.sectionRight}>
          <Info text={this.props.info} />
        </div>
      </>
    );
  }
}
