import React, { Component } from 'react';
import style from './redeem.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import { navigate } from '@reach/router';
import { ContentPage } from '../components/contentpage';

const API_ENDPOINT = process.env.GATSBY_API_URL;
class Redeem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redeemCode: '',
      error: null,
      isSending: false
    };
  }
  onRedeemCodeChange = e => {
    this.setState({ redeemCode: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ error: null, isSending: true });
    fetch(`${API_ENDPOINT}/api/redeem`, {
      headers: {
        'X-Auth-Token': this.state.redeemCode,
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            navigate('/register');
            break;
          case 400:
          case 403:
            this.setState({
              error: 'Ungültiger Zugangscode.',
              isSending: false
            });
            break;
          case 423:
            this.setState({
              error: 'Ihr Zugangscode wurde schon verwendet.',
              isSending: false
            });
            break;
          default:
            this.setState({
              error: 'Es ist etwas schief gelaufen.',
              isSending: false
            });
        }
      })
      .catch(() => {
        this.setState({
          error:
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.',
          isSending: false
        });
      });
  };

  render() {
    const { redeemCode, error, isSending } = this.state;
    return (
      <ContentPage>
        <div className={style.redeem}>
          <h2>Zugangscode eingeben</h2>
          <form id="redeemForm" onSubmit={this.onSubmit}>
            <div className={style.redeemGroup}>
              <input
                name="redeemCode"
                placeholder="Ihr persönlicher Zugangscode"
                type="text"
                value={redeemCode}
                onChange={this.onRedeemCodeChange}
              />
              {error && <div className="formHint">{error}</div>}
            </div>
            <div className={style.redeemSubmit}>
              <Button type="submit" modifier={isSending && 'loading'} disabled={isSending}>
                {isSending && <Spinner />}
                Absenden
              </Button>
            </div>
          </form>
        </div>
      </ContentPage>
    );
  }
}

export default Redeem;
