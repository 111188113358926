const add = (name, value) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(name, JSON.stringify(value));
  }
};

const get = name => {
  if (typeof window !== 'undefined') {
    return JSON.parse(window.localStorage.getItem(name));
  }

  return undefined;
};

const remove = name => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(name);
  }
};

const containsAll = (param0, values) => {
  const checkArray = Array.isArray(param0) ? param0 : get(param0);

  if (!Array.isArray(checkArray) || !Array.isArray(values)) {
    return false;
  }

  values.forEach(element => {
    if (checkArray.indexOf(element) < 0) {
      return false;
    }
  });

  return true;
};

export { add, get, remove, containsAll };
