import React from 'react';
import { Image } from './image';
import style from './module-text-image.module.scss';
import ReactMarkdown from 'react-markdown';
import { blankLinks } from '../modules/markdown/plugins';
import { withLightbox, withLightBoxGallery } from './lightbox';

export const ModuleTextImage = props => {
  let imageClass = style.textImage;
  if (props.sidebarView) {
    imageClass += ' ' + style[props.sidebarView];
  }

  imageClass += ' ' + style[props.isImageLeft ? 'left' : 'right'];
  imageClass += props.modifier ? ' ' + style[props.modifier] : '';

  const noLightbox = Boolean(props.noLightbox);

  const component =
    props.image.gallery && props.image.gallery.length > 0 ? (
      withLightBoxGallery(() => <Image filename={props.image.file} />, props.image.gallery)
    ) : noLightbox ? (
      <Image filename={props.image.file} />
    ) : (
      withLightbox(() => <Image filename={props.image.file} />)
    );

  const textClass = style.text;

  return (
    <div className={imageClass}>
      {Boolean(props.title) && <h2 dangerouslySetInnerHTML={{ __html: props.title }} />}
      {!props.isImageLeft && <div className={style.image}>{component}</div>}
      {props.text && (
        <ReactMarkdown
          className={textClass}
          source={props.text}
          renderers={{
            blanklink: props => (
              <a href={props.url} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            )
          }}
          astPlugins={[blankLinks]}
        />
      )}

      {props.isImageLeft && <div className={style.image}>{component}</div>}
    </div>
  );
};
