import React, { Component, useState } from 'react';
import style from './mapbox-map.module.scss';
import { CreateLocalStorageProvider } from '../localstorageprovider';
import { StaticQuery, graphql } from 'gatsby';
import { WindowSizeContext } from '../context/WindowSizeContext';
require('mapbox-gl/dist/mapbox-gl.css');

let zoomStorage, centerStorage;

let LMap, GeoJSONLayer, ZoomControl;

const MapControls = ({ link, title, icon, change, activeLink }) => {
  const [showHint, setShowHint] = useState(false);
  return (
    <li
      key={link}
      onClick={() => change(link)}
      onMouseEnter={() => setShowHint(true)}
      onFocus={() => setShowHint(true)}
      onMouseLeave={() => setShowHint(false)}
      onBlur={() => setShowHint(false)}
      className={activeLink === link ? style['active'] : ''}
    >
      <img alt={title || ''} src={require(`../${icon}`)} className="likeButton" />
      <span
        className={style['hint']}
        style={{
          transform: `translate(${
            Boolean(showHint) ? 'calc(100% + 7px), -50%' : 'calc(130% + 7px), -50%'
          })`,
          opacity: Boolean(showHint) ? 1 : 0
        }}
      >
        {title || ''}
      </span>
    </li>
  );
};

export class MapboxMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: props.center[0],
      lng: props.center[1],
      zoom: props.zoom,
      mapStyle: props.mapStyle
    };

    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    zoomStorage = CreateLocalStorageProvider('map-zoom');
    centerStorage = CreateLocalStorageProvider('map-center');

    const centerFromStorage = centerStorage.get();
    const zoom = parseFloat(zoomStorage.get()) || this.props.zoom;
    const lat =
      (centerFromStorage && parseFloat(JSON.parse(centerFromStorage).lat)) || this.props.center[0];
    const lng =
      (centerFromStorage && parseFloat(JSON.parse(centerFromStorage).lng)) || this.props.center[1];
    const ReactMapboxGl = require('react-mapbox-gl');

    this.setState({ zoom, lat, lng });

    GeoJSONLayer = ReactMapboxGl.GeoJSONLayer;
    LMap = ReactMapboxGl.Map({
      accessToken: 'pk.eyJ1IjoiY2hlZmZlbiIsImEiOiJDNDA5QkI0In0.tnM3DspM5Kesse6J0YrR_g',
      minZoom: this.props.minZoom || 11.8,
      maxZoom: this.props.maxZoom || 17
    });
    ZoomControl = ReactMapboxGl.ZoomControl;
    this.forceUpdate();
  }

  componentWillUnmount() {
    this._mounted = false;
  }
  componentWillUpdate() {
    if (window) {
      zoomStorage = CreateLocalStorageProvider('map-zoom');
      centerStorage = CreateLocalStorageProvider('map-center');
    }
  }

  onDragEnd = map => {
    const center = map.getCenter();
    centerStorage.set(JSON.stringify([center.lat, center.lng]));
  };

  onZoomEnd = map => {
    const zoom = map.getZoom();
    zoomStorage.set(zoom);
  };

  onStyleLoad = () => {
    if (this._mounted) {
      this.setState({
        lat: this.props.center[0],
        lng: this.props.center[1],
        zoom: this.props.zoom
      });
      zoomStorage.set(this.props.zoom);
      centerStorage.set(JSON.stringify(this.props.center));
    }
  };

  render() {
    const shouldUpdateMapPosition =
      typeof this.props.updatePosition === 'undefined' ? true : this.props.updatePosition;
    const shouldUpdateZoom =
      typeof this.props.updateZoom === 'undefined' ? true : this.props.updateZoom;

    const [lat, lng] = this.props.center;
    const zoom = shouldUpdateZoom
      ? [this.state.zoom]
      : [zoomStorage ? zoomStorage.get() : this.state.zoom];
    const center = shouldUpdateMapPosition
      ? { lat: this.state.lat, lng: this.state.lng }
      : centerStorage && JSON.parse(centerStorage.get()) !== null
      ? { lat: JSON.parse(centerStorage.get())[0], lng: JSON.parse(centerStorage.get())[1] }
      : { lat: lat, lng: lng };

    return (
      <WindowSizeContext.Consumer>
        {isMobile => {
          if (isMobile) return;

          return (
            <StaticQuery
              query={graphql`
                {
                  allDataJson(filter: { page: { eq: "map" } }) {
                    edges {
                      node {
                        map {
                          styles {
                            link
                            icon
                            title
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                  <aside className={style.mapControls}>
                    <ul>
                      {data.allDataJson.edges[0].node.map.styles.map(item => {
                        return (
                          <MapControls
                            key={item.title}
                            {...item}
                            change={link => this.setState({ mapStyle: link })}
                            activeLink={this.state.mapStyle}
                          />
                        );
                      })}
                    </ul>
                  </aside>
                  <div
                    style={{
                      textAlign: 'center',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      left: 0,
                      top: 0
                    }}
                  >
                    {LMap && ( // prevent Mapbox from being server side rendered
                      <LMap
                        animate={true}
                        center={center}
                        zoom={zoom}
                        style={this.state.mapStyle}
                        className={style.map}
                        dragRotate={false}
                        onZoomEnd={this.onZoomEnd}
                        onDragEnd={this.onDragEnd}
                        //onClick={(_, e) => console.log([e.lngLat.lng, e.lngLat.lat])}
                        onStyleLoad={this.onStyleLoad}
                      >
                        <ZoomControl
                          position="bottom-left"
                          style={{
                            bottom: 50
                          }}
                        />
                        {this.props.mapFeatures &&
                          this.props.mapFeatures.map((e, i) => (
                            <GeoJSONLayer
                              key={i} // there could probably be a better index
                              fillPaint={{
                                'fill-opacity': e.fillProperties.fill_opacity,
                                'fill-color': e.fillProperties.fill_color
                              }}
                              data={{
                                type: 'Feature',
                                geometry: {
                                  type: 'Polygon',
                                  coordinates: [e.shape]
                                }
                              }}
                            />
                          ))}
                        {this.props.children}
                      </LMap>
                    )}
                  </div>
                </>
              )}
            />
          );
        }}
      </WindowSizeContext.Consumer>
    );
  }
}
