import React, { Component } from 'react';

import style from './feedback.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import { UserContext } from '../context/UserContext';
import { sendFeedback } from '../api/feedback';
import { ContentPage } from '../components/contentpage';
import { Recaptcha } from '../components/recaptcha';

class FeedbackPage extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;
    const { email, username } = user;

    this.state = {
      subject: '',
      text: '',
      email,
      name: username || '',
      error: null,
      isSending: false,
      success: false,
      captchaToken: undefined,
      dsgvo: false
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeCheck = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.state.captchaToken === null) {
      this.setState({
        error: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.captchaToken === undefined) {
      this.setState({
        error: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    this.setState({ error: null, isSending: true });
    sendFeedback({
      subject: this.state.subject,
      text: this.state.text,
      email: this.state.email,
      name: this.state.name,
      captchaToken: this.state.captchaToken
    })
      .then(([_, err]) => {
        if (err) {
          return this.setState({ isSending: false, success: false, error: err.message });
        }

        this.setState({ success: true, isSending: false, error: null });
      })
      .catch(e => {
        console.error(e);

        this.setState({
          isSending: false,
          success: false,
          error: 'Something unexpected happened!'
        });
      });
  };

  render() {
    const { subject, text, email, name, error, isSending, success, dsgvo } = this.state;
    const isLoggedIn = this.props.user && this.props.user.loggedIn === true;

    return (
      <ContentPage>
        <div className={style.feedback}>
          <h2>Kontakt</h2>
          {success ? (
            <p>Danke für Ihr Feedback!</p>
          ) : (
            <form onSubmit={this.onSubmit}>
              <div className={style.feedbackGroup}>
                <label>
                  <span>Name*</span>
                  <input
                    required
                    placeholder="Vor- und Nachname"
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.onChange}
                  />
                </label>
                <br />
                <label>
                  <span>E-Mail{Boolean(isLoggedIn) ? `: ${email}` : '*'}</span>
                  <input
                    required
                    placeholder="Ihre E-Mail-Adresse"
                    type={isLoggedIn ? 'hidden' : 'email'}
                    name="email"
                    value={email || ''}
                    onChange={this.onChange}
                  />
                </label>
                <br />
                <label>
                  <span>Betreff*</span>
                  <input
                    required
                    placeholder="Betreff"
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={this.onChange}
                  />
                </label>
                <br />
                <label>
                  <span>Bitte formulieren Sie Ihre Anfrage*</span>
                  <textarea required name="text" value={text} onChange={this.onChange} />
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    name="dsgvo"
                    onChange={this.onChangeCheck}
                    value={dsgvo ? 'yes' : 'no'}
                    checked={dsgvo}
                    style={{ width: 0, height: 0, margin: 0, padding: 0, opacity: 0 }}
                  />
                  <span>
                    <span className={`checkbox${dsgvo ? ' checked' : ''}`}></span>
                    Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme zu, dass
                    meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und
                    gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die
                    Zukunft per E-Mail an info@plankoopertativ.de widerrufen.
                  </span>
                </label>
                <br />
                <Recaptcha
                  style={{ marginBottom: '12px' }}
                  elementID={'feedback-captcha'}
                  verifyCallback={token => {
                    this.setState({ captchaToken: token });
                  }}
                  expiredCallback={() => {
                    this.setState({ captchaToken: null });
                  }}
                />

                {error && <div className="formHint">{error}</div>}
              </div>
              <div>
                <Button type="submit" modifier={isSending && 'loading'} disabled={isSending}>
                  {isSending && <Spinner />}
                  Absenden
                </Button>
              </div>
            </form>
          )}
        </div>
      </ContentPage>
    );
  }
}

const userDataToProps = props => {
  return (
    <UserContext.Consumer>
      {data => {
        if (data.loading) {
          return <Spinner modifier={'dark'} />;
        }
        return <FeedbackPage {...props} user={data} />;
      }}
    </UserContext.Consumer>
  );
};

export default userDataToProps;
