import React from 'react';
import style from '../components/tile.module.scss';
import postStyle from '../components/post-tile.module.scss';
import { API_ENDPOINT } from '../api';
import { CommentIcon } from './icons/comment-icon';

/**
 * @param {object} param0
 * @param {() => void} param0.toggleVisibility
 * @param {boolean} param0.isAdmin
 */
export const Tile = ({
  post,
  like,
  category,
  className,
  toggleVisibility,
  isAdmin,
  interaction,
  ...rest
}) => {
  const { image, title, content, likes, liked, visible, comments: commentCount } = post;

  return (
    <div {...rest} className={`${className} ${postStyle.postTile} ${image ? '' : style.noImage}`}>
      {isAdmin && (
        <span
          style={{ position: 'absolute', left: 5, top: 5, zIndex: 100 }}
          onClick={event => {
            event.stopPropagation();
            toggleVisibility();
          }}
        >
          {visible ? 'ausblenden' : 'anzeigen'}
        </span>
      )}
      <div className={postStyle.categoryCircle}>
        {category && (
          <img
            src={
              category.image.file.startsWith('http')
                ? category.image.file
                : require('../images/' + category.image.file)
            }
            style={{ width: '100%' }}
            alt=""
          />
        )}
      </div>
      <div className={postStyle.tileImage}>
        <figure
          style={{
            paddingBottom: '100%',
            position: 'relative',
            margin: 0
          }}
        >
          {image && (
            <img
              alt={title}
              src={`${API_ENDPOINT}${image}`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          )}
        </figure>
      </div>

      <div className={style.content}>
        <h2>{title}</h2>

        <p className={style.description}>
          {content} <b>mehr ...</b>
        </p>
      </div>

      <span className={`${style.lastUpdated} ${postStyle.likeButton}`}>
        <div className={postStyle.countBox}>{commentCount}</div>
        <CommentIcon
          style={{ margin: '0px 7px -8px 1px', width: '25px', height: '25px', fill: '#0095db' }}
        />
        <div className={postStyle.countBox}>{likes}</div>

        <img
          src={require(`../images/heart-${liked ? 'filled' : 'outline'}.png`)}
          alt="like"
          className="likeButton"
        />
      </span>
    </div>
  );
};
