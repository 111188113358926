import React from 'react';
// import postStyle from './post-tile.module.scss';
import style from './comment.module.scss';
import PopupDialog from './popup-dialog';
import { Button } from './button';
import { BlameIcon } from './icons/blame-icon';
import postStyle from './post-tile.module.scss';
import postCreateStyle from './post-create.module.scss';
import { Recaptcha } from './recaptcha';

// {
//   "comment": "Super Vorschlag!",
//   "created": "2019-03-20T08:55:52Z",
//   "liked": false,
//   "likes": 0,
//   "slug": "lionralfs_mein_supertoller_vorschlag",
//   "user": {
//     "username": "lionralfs",
//     "department": "Software Developer",
//     "avatar": null,
//     "roles": [
//       "ROLE_USER"
//     ],
//     "email": "lion@webschuppen.com"
//   },
//   "visible": true
// }

const dateToTimeAgo = dateString => {
  const date = new Date(dateString);
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Jahr' : 'Jahren'}`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Monat' : 'Monaten'}`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Tag' : 'Tagen'}`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `Vor ${interval} ${interval === 1 ? 'Stunde' : 'Stunden'}`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `Vor ${interval} ${interval === 1 ? 'Minute' : 'Minuten'}`;
  }
  return `Vor weniger als einer Minute`;
};

export const Comment = props => {
  const clearComment = comment =>
    comment
      .replace(/>/g, '&gt;')
      .replace(/</g, '&lt;')
      .replace(/[\n]{2,}/g, '<br/><br/>')
      .replace(/[\n]{1}/g, '<br/>');

  return (
    <>
      {props.blameDialogOpen && (
        <PopupDialog
          onClose={props.onBlameClose}
          image={require('../images/maennchen-warning.png')}
        >
          <h2>Diesen Kommentar melden</h2>
          <form onSubmit={evt => evt.preventDefault()}>
            <label>Warum melden?</label>
            <textarea
              name="reason"
              value={props.blameReason}
              onChange={props.onBlameReasonChange}
            />
            <label>E-Mail-Adresse</label>
            <input
              type="text"
              name="email"
              value={props.emailInput}
              onChange={props.onEmailInputChange}
            />
            <Recaptcha
              elementID={props.captchaElementID}
              style={{
                display: 'inline-block',
                width: '100%'
              }}
              expiredCallback={props.captchaExpiredCallback}
              verifyCallback={props.captchaVerifyCallback}
            />
            {props.captchaError && (
              <span
                style={{
                  display: 'block',
                  marginBottom: '15px',
                  color: 'red'
                }}
              >
                {props.captchaError}
              </span>
            )}
            <Button
              modifier={props.loading && 'loading'}
              disabled={props.loading}
              onClick={props.onSubmit}
            >
              Absenden
            </Button>
          </form>
        </PopupDialog>
      )}
      <div className={style.comment}>
        <div className={style.topBar}>
          {props.username && <span className={style.username}>{props.user.username}</span>}{' '}
          <span className={style.createdAt}>{dateToTimeAgo(props.created)}</span>
        </div>
        <div className={style.commentText}>
          <span dangerouslySetInnerHTML={{ __html: clearComment(props.comment) }} />
        </div>
        <div className={style.actions}>
          <span
            className={`${postCreateStyle.lastUpdated} ${postStyle.likeButton}`}
            style={{ float: 'left', marginRight: '12px' }}
          >
            <div className={postStyle.countBox}>{props.likes}</div>
            <img
              src={require(`../images/heart-${props.liked ? 'filled' : 'outline'}.png`)}
              alt="like"
              className="likeButton"
              onClick={props.onLike}
            />
          </span>
          <span
            style={{
              cursor: 'pointer',
              marginTop: '2px'
            }}
            onClick={props.onBlameInit}
          >
            <BlameIcon />
          </span>
        </div>
      </div>
    </>
  );
};
