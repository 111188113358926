import React from 'react';
import ReactMarkdown from 'react-markdown';

import style from './module-text-image.module.scss';
import { isFunction } from '../utils';
import { blankLinks } from '../modules/markdown/plugins';
const noop = () => {};

export const ModuleText = props => {
  const { onClick, title, sections } = props;

  const hasClickHandler = isFunction(onClick);

  let className = style.module;
  if (hasClickHandler) {
    className += ' has-fadeout';
  }

  return (
    <div
      className={className}
      onClick={hasClickHandler ? onClick : noop}
      style={{ cursor: hasClickHandler ? 'pointer' : 'normal' }}
    >
      {title && <h2>{title}</h2>}
      {sections.map((props, i) => {
        return (
          <ReactMarkdown
            key={i}
            source={props.text}
            renderers={{
              blanklink: props => (
                <a href={props.url} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              )
            }}
            astPlugins={[blankLinks]}
          />
        );
      })}
    </div>
  );
};
