import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import style from './login.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import { UserContext } from '../context/UserContext';
import { login } from '../api/user';
import { ContentPage } from '../components/contentpage';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      error: null,
      isSending: false
    };
  }

  onUserNameChange = e => {
    this.setState({ username: e.target.value });
  };

  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ error: null, isSending: true });

    login({ username: this.state.username, password: this.state.password })
      .then(([user, err]) => {
        if (err) {
          return this.setState({ isSending: false, error: err.message });
        }

        this.setState({ isSending: false });
        this.context.login(user);
        const linkBackTo =
          (this.props.location &&
            this.props.location.state &&
            this.props.location.state.linkBackTo) ||
          '/';
        navigate(linkBackTo);
      })
      .catch(e => {
        console.error(e);
        // something completely unexpected happened, since `login()` is only supposed to resolve
        this.setState({ error: 'Es ist etwas schief gelaufen.', isSending: false });
      });
  };

  render() {
    const { username, password, error, isSending } = this.state;
    return (
      <ContentPage>
        <div className={style.login}>
          <h2>Login</h2>
          <form onSubmit={this.onSubmit}>
            <div className={style.loginGroup}>
              <label>
                <span>Benutzername</span>
                <input
                  required
                  placeholder="Ingrid Maier"
                  type="text"
                  name="username"
                  value={username}
                  onChange={this.onUserNameChange}
                />
              </label>
              <br />

              <label>
                <span>Passwort</span>
                <input
                  required
                  placeholder="********"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onPasswordChange}
                />
              </label>
              <br />
              {error && <div className="formHint">{error}</div>}
            </div>
            <div>
              <Button type="submit" modifier={isSending && 'loading'} disabled={isSending}>
                {isSending && <Spinner />}
                Einloggen
              </Button>
            </div>
          </form>
          <div className={style.redeemLink}>
            <Link to="/redeem" title="Zur Anmeldung">
              Zur Anmeldung
            </Link>
          </div>
        </div>
      </ContentPage>
    );
  }
}

Login.contextType = UserContext;
