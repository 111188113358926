/**
 * Creates an object which has access to window.localStorage using the provided key
 * @param {string} [key] If supplied, returns an instance that only operates on that key
 */
export const CreateLocalStorageProvider = key =>
  key
    ? {
        get() {
          return window.localStorage.getItem(key);
        },
        set(val) {
          window.localStorage.setItem(key, val);
        }
      }
    : window.localStorage;
