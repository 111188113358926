import React from 'react';

import SEO from '../components/seo';
import { ContentPage } from '../components/contentpage';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <ContentPage>
    <SEO title="404 Seite nicht gefunden" />
    <h2>404 Seite nicht gefunden</h2>

    <p>Diese Seite existiert leider nicht.</p>
    <Link to="/">Zurück zur Startseite</Link>
  </ContentPage>
);

export default NotFoundPage;
