import React from 'react';
import { Link } from '@reach/router';
import Layout from '../components/layout';
import { Sidebar } from '../components/sidebar';
import tileStyle from '../components/tile.module.scss';
import { Tile } from '../components/tile';
import { Arrow } from '../components/arrow';
import { CloseButton } from '../components/closeButton';
import sidebarStyle from '../components/sidebar.module.scss';
import { MapboxMap } from '../components/mapbox-map';
import { Footer } from '../components/footer';
import { TitleText } from '../components/title-text';
import { navigate } from 'gatsby';
import WarningPost from '../components/warning-post';
import { containsAll } from '../api/localstorage-provider';

export default ({ pageContext }) => {
  const {
    colorCode,
    textHighlightColor,
    title,
    subTitle,
    variants,
    description,
    previous,
    next,
    needs,
    mapData
  } = pageContext;

  const showDialog = Array.isArray(needs) && needs.length > 0 && !containsAll('posted', needs);
  const { lat, lng, zoom, minZoom, maxZoom, style } = mapData;

  let tileClasses = tileStyle.tile;

  return (
    <Layout>
      <MapboxMap
        center={[lat, lng]}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        mapStyle={style}
      />
      <Sidebar
        render={currentSidebarState => (
          <>
            <div className={sidebarStyle.buttonBar}>
              {previous && (
                <Link to={previous}>
                  <Arrow.Left aria-label="Vorherige Seite" />
                </Link>
              )}
              {next && (
                <Link to={next}>
                  <Arrow.Right aria-label="Nächste Seite" />
                </Link>
              )}
              <Link to="/map" title="Zurück">
                <CloseButton className={sidebarStyle.closePage} />
              </Link>
            </div>

            <TitleText
              description={description}
              title={title}
              subTitle={subTitle}
              colorCode={colorCode}
              textHighlightColor={textHighlightColor}
            />

            <div className={tileStyle.tileWrapper}>
              {variants.map((variant, i) => (
                <Tile
                  {...variant}
                  key={variant.title + i}
                  className={tileClasses + ' ' + tileStyle[currentSidebarState]}
                  letter={variant.tileTitle}
                  colorCode={variant.colorCode}
                  textHighlightColor={variant.textHighlightColor}
                />
              ))}
            </div>
          </>
        )}
      />
      {showDialog && (
        <WarningPost
          onClose={() => {
            return navigate('/map/district/allgemeines');
          }}
        />
      )}
      <Footer />
    </Layout>
  );
};
