import React from 'react';

export const ArrowDown = props => (
  <svg height="32px" id="svg2" version="1.1" viewBox="0 0 32 32" width="32px" {...props}>
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="arrow_x5F_full_x5F_down">
      <polygon
        points="29.998,16 22,16 22,2 9.999,2 9.999,16 1.999,16 15.999,30"
        fill="rgba(0,0,0,0.3)"
      />
    </g>
  </svg>
);
