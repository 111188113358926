function* walkTree(tree, parent = null, position = 0, level = 0) {
  const current = { tree, parent, position, level };
  yield current;
  if (tree.children) {
    let i;
    for (i = 0; i < tree.children.length; i++) {
      yield* walkTree(tree.children[i], current, i, level + 1);
    }
  }
}

const blankLinks = tree => {
  let node;
  let lastNode;

  for (node of walkTree(tree)) {
    const item = node.tree;

    if (item.type === 'link') {
      const regex = RegExp('({blank})');
      const linktext = item.children[0].value;
      if (regex.test(linktext, 'g')) {
        const cleanedLinktext = linktext.replace(regex, '');
        item.children[0].value = cleanedLinktext;
        item.type = 'blanklink';
      }
    }

    lastNode = node;
  }
  return tree;
};

export { blankLinks };
