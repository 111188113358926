import React, { useEffect } from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';
import { get, add } from '../../api/localstorage-provider';

/**
 *
 * @param {object} props
 */
const Alias = props => {
  const { alias, title, placeholder, info, required, onChange: onChangeProp } = props;

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const onChange = event => {
    const alias = event.target.value;
    add('alias', alias);
    if (isFunction(onChangeProp)) onChangeProp(alias);
  };

  useEffect(() => {
    onChange({ target: { value: get('alias') || '' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {title.length > 0 && <h2>{title}</h2>}

      <div className={postCreateStyle.sectionLeft} style={{ paddingRight: '25%' }}>
        <div style={{ position: 'relative' }}>
          <input
            name="alias"
            type="text"
            value={alias}
            placeholder={placeholder}
            onChange={onChange}
          />
          {!required && (
            <span
              className={postCreateStyle.optionalTag}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                marginRight: 0
              }}
            >
              optional
            </span>
          )}
        </div>
      </div>
      <div className={postCreateStyle.sectionRight}>
        <Info text={info} />
      </div>
    </>
  );
};

export { Alias };
