import React, { Component } from 'react';
import style from './file-upload.module.scss';
import AvatarEditor from 'react-avatar-editor';

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: props.initialFile || '',
      currentFile: undefined,
      scale: 1.2,
      rotation: 0
    };
  }

  handleChange = e => {
    console.log('handle image change event');
    this.setState({ currentFile: e.target.files[0] }, () => {
      // this.getImage(this.props.onChange);
    });
  };

  handleHidden = () => {};

  setEditorRef = editor => {
    this.editor = editor;
  };

  getImage = cb => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImageScaledToCanvas();
      const dataURL = canvas.toDataURL();
      cb(dataURL);
    }
  };

  render() {
    let classNames = style.fileupload;

    return (
      <>
        {this.state.currentFile !== undefined && (
          <>
            <AvatarEditor
              image={this.state.currentFile}
              ref={this.setEditorRef}
              width={376}
              height={231}
              border={12}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={this.state.scale}
              rotate={this.state.rotation}
              onMouseUp={() => this.getImage(this.props.onChange)}
              onLoadSuccess={() => this.getImage(this.props.onChange)}
            />
            <div style={{ textAlign: 'center', width: '206px' }}>
              <button
                className={style.rotateButton}
                onClick={() =>
                  this.setState({ rotation: (this.state.rotation - 90) % 360 }, () => {
                    this.getImage(this.props.onChange);
                  })
                }
              >
                <IconRotateLeft />
              </button>
              <button
                className={style.rotateButton}
                onClick={() =>
                  this.setState({ rotation: (this.state.rotation + 90) % 360 }, () => {
                    this.getImage(this.props.onChange);
                  })
                }
              >
                <IconRotateRight />
              </button>
            </div>
            <input
              type="range"
              step="0.01"
              min="1"
              max="2"
              name="scale"
              value={this.state.scale}
              onChange={e => {
                this.setState({ scale: parseFloat(e.target.value) }, () => {
                  this.getImage(this.props.onChange);
                });
              }}
              style={{
                minWidth: 'auto',
                width: '206px',
                display: 'block'
              }}
            ></input>
          </>
        )}
        <label className={classNames}>
          <span>{this.props.label}</span>
          <div>
            <input type="file" onChange={this.handleChange} />
            <input
              type="hidden"
              name={this.props.fieldName || 'file'}
              value={this.state.fileName}
              onChange={this.handleHidden}
            />
          </div>
        </label>
      </>
    );
  }
}

const IconRotateLeft = () => {
  return <IconRotateRight style={{ transform: 'scaleX(-1)' }} />;
};

const IconRotateRight = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" {...props}>
      <path
        fill="#444"
        d="M16 7V3l-1.1 1.1C13.6 1.6 11 0 8 0 3.6 0 0 3.6 0 8s3.6 8 8 8c2.4 0 4.6-1.1 6-2.8l-1.5-1.3C11.4 13.2 9.8 14 8 14c-3.3 0-6-2.7-6-6s2.7-6 6-6c2.4 0 4.5 1.5 5.5 3.5L12 7h4z"
      />
    </svg>
  );
};

export default FileUpload;
