import React from 'react';
import style from './arrow.module.scss';

const GenericArow = props => {
  return <span role="img" {...props} />;
};

export const Arrow = {
  Left: props => <GenericArow className={style.leftArrow} {...props} />,
  Right: props => <GenericArow className={style.rightArrow} {...props} />
};
