import React from 'react';

export const BlameIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 485.249 485.249"
    style={{ width: '25px', height: '25px' }}
    {...props}
  >
    <g fill="#0095db">
      <path d="M481.249 420.625l-219-380c-4-7-11-11-19-11s-15 4-19 11l-221 382c-10 17 5 33 19 33h442c12 0 21-10 21-22 0-5-1-9-4-13zm-421-9l183-317 183 317h-366z" />
      <path d="M221.249 192.625v97c0 12 10 22 22 22s22-10 22-22v-97c0-12-10-22-22-22s-22 10-22 22zM243.249 330.625c-15 0-27 12-27 27s12 28 27 28 27-13 27-28-12-27-27-27z" />
    </g>
  </svg>
);
