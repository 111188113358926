import React, { Component } from 'react';
import style from './accordeon.module.scss';

export default class Accordeon extends Component {
  render() {
    return <div className={style.accordeon}>{this.props.children}</div>;
  }
}
// Todo: Accordeon functions (open/close)
export class AccordeonTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: !!props.open
    };
  }
  render() {
    const {
      title,
      children
      //open = false
    } = this.props;

    return (
      <div className={style.accordeonTile}>
        <h2 className={style.title} onClick={() => this.setState({ open: !this.state.open })}>
          {title || 'undefined'}
        </h2>
        <div className={`${style.content} ${this.state.open && style.open}`}>{children}</div>
      </div>
    );
  }
}
