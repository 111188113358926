import React from 'react';
import { Image } from './image';
import style from './module-image-row.module.scss';
import { withLightbox } from './lightbox';
import { isFunction } from '../utils';

export const ModuleImageRow = props => {
  const hasClickHandler = isFunction(props.onClick);

  return (
    <div className={style.imageRow}>
      {props.images.map((image, i) => {
        return (
          <div key={image.file + i} style={{ width: `${100 / props.images.length}%` }}>
            {hasClickHandler ? (
              <div onClick={props.onClick} style={{ cursor: 'pointer' }}>
                <Image filename={image.file} caption={image.caption} />
              </div>
            ) : (
              withLightbox(() => <Image filename={image.file} caption={image.caption} />)
            )}
          </div>
        );
      })}
      {props.text && <p>{props.text}</p>}
    </div>
  );
};
