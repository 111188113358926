import React from 'react';
import { Info } from './info';
import { isFunction } from '../../utils';

import postCreateStyle from '../../components/post-create.module.scss';
import style from './textinputs.module.scss';

const Title = props => {
  /**
   *
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const onChange = event => {
    const title = event.target.value;

    if (isFunction(props.onChange)) props.onChange(title);
  };

  const length = props.maxchars || 190;
  const { required, title, inputTitle, placeholder, info } = props;

  return (
    <>
      <h2>
        {!required && <span className={postCreateStyle.optionalTag}>optional</span>}
        {title}
      </h2>
      <div className={postCreateStyle.sectionLeft}>
        <input
          type="text"
          name="title"
          value={inputTitle}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={length}
        />
        <span className={style.charcounter}>
          {inputTitle.length}/{length} Zeichen
        </span>
      </div>
      <div className={postCreateStyle.sectionRight}>
        <Info text={info} />
      </div>
    </>
  );
};

export { Title };
