import React, { Component } from 'react';
import style from './flip-clock.module.scss';

const AnimatedCard = ({ animation, digit }) => {
  return (
    <div className={`${style.flipCard} ${style[animation]}`}>
      <span>{digit}</span>
    </div>
  );
};

const StaticCard = ({ position, digit }) => {
  return (
    <div className={style[position]}>
      <span>{digit}</span>
    </div>
  );
};

const FlipUnitContainer = ({ digit, shuffle, unit }) => {
  // assign digit values
  let currentDigit = digit;
  let previousDigit = digit + 1;

  // to prevent an overflow value
  // if (unit === 'minutes') {
  //   previousDigit = previousDigit === -1 ? 59 : previousDigit;
  // } else {
  //   previousDigit = previousDigit === -1 ? 23 : previousDigit;
  // }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`;
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`;
  }

  // shuffle digits
  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  // shuffle animations
  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return (
    <div className={style.flipUnitContainer}>
      <StaticCard position={'upperCard'} digit={currentDigit} />
      <StaticCard position={'lowerCard'} digit={previousDigit} />
      <AnimatedCard digit={digit1} animation={animation1} />
      <AnimatedCard digit={digit2} animation={animation2} />
    </div>
  );
};

export class FlipClock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      daysShuffle: true,
      hours: 0,
      hoursShuffle: true,
      minutes: 0,
      minutesShuffle: true,
      seconds: 0,
      secondsShuffle: true,
      endDate: new Date(props.endDate)
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.updateTime(), 50);
    this.updateTime();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateTime() {
    // get new date
    const now = new Date().getTime();
    const endDate = this.state.endDate.getTime();

    let delta = Math.abs(endDate - now) / 1000;

    if (endDate - now <= 0) {
      clearInterval(this.timerID);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      });

      return;
    }

    // set time units
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = Math.floor(delta % 60);

    // on day change, update day and shuffle state
    if (days !== this.state.days) {
      const daysShuffle = !this.state.daysShuffle;
      this.setState({
        days,
        daysShuffle
      });
    }

    // on hour change, update hours and shuffle state
    if (hours !== this.state.hours) {
      const hoursShuffle = !this.state.hoursShuffle;
      this.setState({
        hours,
        hoursShuffle
      });
    }

    // on minute chanage, update minutes and shuffle state
    if (minutes !== this.state.minutes) {
      const minutesShuffle = !this.state.minutesShuffle;
      this.setState({
        minutes,
        minutesShuffle
      });
    }

    // on seconds chanage, update seconds and shuffle state
    if (seconds !== this.state.seconds) {
      const secondsShuffle = !this.state.secondsShuffle;
      this.setState({
        seconds,
        secondsShuffle
      });
    }
  }

  render() {
    // state object destructuring
    const {
      days,
      hours,
      minutes,
      // seconds,
      daysShuffle,
      hoursShuffle,
      minutesShuffle
      // secondsShuffle
    } = this.state;

    return (
      <>
        <h2 style={{ textAlign: 'center' }}>{this.props.title}</h2>
        <div className={style.flipClock}>
          <FlipUnitContainer unit={'days'} digit={days} shuffle={daysShuffle} />
          <FlipUnitContainer unit={'hours'} digit={hours} shuffle={hoursShuffle} />
          <FlipUnitContainer unit={'minutes'} digit={minutes} shuffle={minutesShuffle} />
          {/* <FlipUnitContainer unit={'seconds'} digit={seconds} shuffle={secondsShuffle} /> */}
        </div>
        <div className={style.textWrapper}>
          <span>Tage</span>
          <span>Stunden</span>
          <span>Minuten</span>
        </div>
      </>
    );
  }
}
