/*
 * @Author: qiuziz
 * @Date: 2017-08-03 17:44:46
 * @Last Modified by: qiuziz
 * @Last Modified time: 2017-08-07 11:08:38
 */

import React from 'react';
import { OPTIONS } from './captcha-config';

export default class Captcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      style: {
        height: props.height + 'px',
        width: props.width + 'px'
      }
    };
  }

  componentDidMount() {
    this.createCodes();
  }

  random(n, m) {
    const c = m - n + 1;
    const num = Math.random() * c + n;
    return Math.floor(num);
  }

  createCodes() {
    const { length, codes } = this.props;
    let result = [],
      lines = [];
    for (let i = 0; i < length; i++) {
      result.push({ code: codes[this.random(0, codes.length - 1)], style: this.codeStyle(i) });
    }
    for (let i = 0; i < OPTIONS.lines; i++) {
      lines.push({ style: this.createLines() });
    }

    this.setState({ result, lines }, () => {
      this.onChange();
    });
  }

  codeStyle(i) {
    const uW = this.props.width / this.props.length; // 每个字符占的宽度

    return {
      fontSize: `${this.random(OPTIONS.fontSizeMin, OPTIONS.fontSizeMax)}px`,
      color: `${OPTIONS.colors[this.random(0, OPTIONS.colors.length - 1)]}`,
      position: 'absolute',
      left: `${this.random(uW * i, uW * i + uW - uW / 2)}px`,
      top: '50%',
      transform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      OTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      MsTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      MozTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      WebkitTransform: `rotate(${this.random(-15, 15)}deg) translateY(-50%)`,
      fontFamily: `${OPTIONS.fonts[this.random(0, OPTIONS.fonts.length - 1)]}`,
      fontWeight: 'bold',
      zIndex: '2'
    };
  }

  createLines() {
    return {
      position: 'absolute',
      opacity: `${this.random(3, 8) / 10}`,
      width: `${this.random(OPTIONS.lineWidthMin, OPTIONS.lineWidthMax)}px`,
      height: `${this.random(OPTIONS.lineHeightMin, OPTIONS.lineHeightMax)}px`,
      background: `${OPTIONS.lineColors[this.random(0, OPTIONS.lineColors.length - 1)]}`,
      left: `${this.random(-OPTIONS.lineWidthMin / 2, this.props.width)}px`,
      top: `${this.random(0, this.props.height)}px`,
      transform: `rotate(${this.random(-30, 30)}deg)`,
      OTransform: `rotate(${this.random(-30, 30)}deg)`,
      MsTransform: `rotate(${this.random(-30, 30)}deg)`,
      MozTransform: `rotate(${this.random(-30, 30)}deg)`,
      WebkitTransform: `rotate(${this.random(-30, 30)}deg)`,
      fontFamily: `${OPTIONS.fonts[this.random(0, OPTIONS.fonts.length - 1)]}`,
      fontWeight: `${this.random(400, 900)}`
    };
  }

  onChange() {
    const { result } = this.state;
    let code = '';
    result.map(item => {
      return (code += item.code);
    });
    this.props.onChange(code);
  }

  render() {
    const { result, lines } = this.state;
    const style = { ...this.state.style, ...this.props.style };

    return (
      <>
        <div style={style}>
          {result.map((item, index) => {
            return (
              <span key={index} style={item.style}>
                {item.code}
              </span>
            );
          })}
          {lines &&
            lines.map((item, index) => {
              return <div key={index} style={item.style} />;
            })}
        </div>
        <button
          onClick={() => this.createCodes()}
          style={{
            background: '#e5e5e5',
            border: 'none',
            cursor: 'pointer',
            display: 'block',
            fontSize: '16px',
            maxWidth: '100%',
            outline: 0,
            borderRadius: '4px',
            color: '#000',
            lineHeight: 1.5,
            position: 'relative',
            boxSizing: 'border-box',
            padding: 'calc(0.375em - 1px) calc(0.625em - 1px)',
            marginTop: '15px',
            marginBottom: '15px'
          }}
        >
          Neuen Code generieren
        </button>
      </>
    );
  }
}
